// HomeLiveStreamAd.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { sanitize } from 'isomorphic-dompurify';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import {
  TYPE_PHOTO,
  TYPE_VIDEO,
  TYPE_LINK,
  TYPE_RICH,
  DOMPURIFY_ADD_TAGS,
} from '../resource/popupNotificationConstants.js';
import getCurrentLanguage from '../resource/getCurrentLanguage.js';
import toEven from '../resource/toEven.js';
import { ButtonId } from '../resource/mixpanel.js';
import { livestreamAdType as livestreamAdTypeConstant } from '../resource/liveStreamConstants.js';

import LoadingIconResource from '../../img/img_loading_white_20.svg';

const REMOTE_CONFIG_TIMEOUT_SECONDS = 3; // TODO: remote config

export class HomeLiveStreamAd extends React.PureComponent {
  state = {
    isClient: false,
    shouldShowAd: true,
  };
  element = null;
  nextTick = null;
  timer = null;
  setRef = element => {
    this.element = element;
  };
  renderRich = () => {
    const { adOembedData } = this.props;
    const html = sanitize(adOembedData?.html, {
      ADD_TAGS: DOMPURIFY_ADD_TAGS,
    });
    return (
      <ContentWrapper>
        <RichContent
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </ContentWrapper>
    );
  };
  renderVideo = () => {
    const { adOembedData } = this.props;
    const html = sanitize(adOembedData?.html)?.replace(
      '<video',
      '<video muted autoplay playsinline'
    );
    return (
      <ContentWrapper>
        <VideoContent
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </ContentWrapper>
    );
  };
  renderPhoto = () => {
    const { adOembedData } = this.props;
    return (
      <ContentWrapper>
        <PhotoContent src={adOembedData?.url} />
      </ContentWrapper>
    );
  };
  renderLink = () => {
    const { adOembedData } = this.props;
    return (
      <ContentWrapper>
        <PhotoContent src={adOembedData?.thumbnail_url} />
      </ContentWrapper>
    );
  };
  fetchOembedData = () => {
    const { adURL, fetchOembedData } = this.props;
    const { isClient } = this.state;
    if (isClient && this.element && adURL) {
      const { width, height } = this.element.getBoundingClientRect();
      return fetchOembedData({
        url: adURL,
        language: getCurrentLanguage(),
        maximumWidth: toEven({ value: width }),
        maximumHeight: toEven({ value: height }),
      });
    }
  };
  componentDidMount() {
    this.fetchOembedData();
    this.nextTick = setTimeout(() => {
      this.setState({
        isClient: true,
      });
    }, 0);
    this.timer = setTimeout(() => {
      this.setState({
        shouldShowAd: false,
      });
    }, REMOTE_CONFIG_TIMEOUT_SECONDS * 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    const { isClient } = this.state;
    const { adURL, isFetched, adOembedData } = this.props;
    if (isClient !== prevState.isClient || adURL !== prevProps.adURL) {
      this.fetchOembedData();
    }
    if (
      isFetched !== prevProps.isFetched ||
      adOembedData !== prevProps.adOembedData
    ) {
      if (isFetched) {
        this.setState({
          shouldShowAd: !!adOembedData,
        });
        clearTimeout(this.timer);
      }
    }
  }
  componentWillUnmount() {
    clearTimeout(this.nextTick);
    clearTimeout(this.timer);
  }
  render() {
    const { adURL, adOembedData, adType, isInternalLink } = this.props;
    const { isClient, shouldShowAd } = this.state;
    if (!shouldShowAd || !adURL) {
      return null;
    }
    const url = new URL(adURL);
    const linkProps = isInternalLink
      ? { as: Link, to: `${url.pathname}${url.search}` }
      : { as: 'a', href: adURL, target: '_blank' };
    const elementId =
      adType === livestreamAdTypeConstant.SWAGGER // FEATURE_LIVESTREAM_AD
        ? ButtonId.LiveStream.ButtonLivestreamAd1
        : adType === livestreamAdTypeConstant.ADVERTISER // FEATURE_LIVESTREAM_AD_2
          ? ButtonId.LiveStream.ButtonLivestreamAd2
          : '';
    const trackingPayload = {
      url: adURL,
      'si.url': adOembedData?.url,
    };

    return (
      <StyledHomeLiveStreamAd ref={this.setRef}>
        {!adOembedData || !isClient ? (
          <LoadingIcon src={LoadingIconResource} />
        ) : (
          <>
            {adOembedData?.type === TYPE_LINK && this.renderLink()}
            {adOembedData?.type === TYPE_PHOTO && this.renderPhoto()}
            {adOembedData?.type === TYPE_VIDEO && this.renderVideo()}
            {adOembedData?.type === TYPE_RICH && this.renderRich()}
            <LiveStreamLink
              {...linkProps}
              data-element_id={elementId}
              data-tracking_payload={trackingPayload}
            />
          </>
        )}
      </StyledHomeLiveStreamAd>
    );
  }
}

HomeLiveStreamAd.propTypes = {
  adURL: PropTypes.string,
  adType: PropTypes.string,
  isFetched: PropTypes.bool,
  isInternalLink: PropTypes.bool,
  adOembedData: PropTypes.object,
  fetchOembedData: PropTypes.func,
};

HomeLiveStreamAd.defaultProps = {
  adURL: null,
  adType: null,
  isFetched: false,
  isInternalLink: false,
  adOembedData: null,
  fetchOembedData: () => null,
};

const StyledHomeLiveStreamAd = styled.div`
  position: relative;
  padding-top: 100%;
`;

const LiveStreamLink = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const ContentWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RichContent = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 100%;
`;

const VideoContent = styled.div`
  width: 100%;
  height: 100%;
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const PhotoContent = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LoadingIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);
`;

export default HomeLiveStreamAd;
