// popupNotificationConstants.js
'use strict';

export const POSITION_TOP = 'top';
export const POSITION_BOTTOM = 'bottom';
export const POSITION_CENTER = 'center';
export const POSITION_FULLSCREEN = 'fullscreen';
export const POSITION_TOP_VIDEO = 'top-video';
export const TYPE_PHOTO = 'photo';
export const TYPE_VIDEO = 'video';
export const TYPE_LINK = 'link';
export const TYPE_RICH = 'rich';

export const STATUS_IDLE = 'idle';
export const STATUS_OPENING = 'coming';
export const STATUS_OPENED = 'display';
export const STATUS_CLOSING = 'closing';

export const MODAL_ID_PREFIX = 'PopupNotification-';

export const DATASET_BUTTON_ID = 'button_id';

export const DOMPURIFY_ADD_ATTR = [`data-${DATASET_BUTTON_ID}`];
export const DOMPURIFY_ADD_TAGS = ['iframe'];

const SELECT_PATH_PREFIX = ['popupNotification'];

export const LIST_SELECT_PATH_PREFIX = SELECT_PATH_PREFIX;
export const DETAIL_SELECT_PATH_PREFIX = [...SELECT_PATH_PREFIX, 'detail'];
export const CONTENT_SIZE_SELECT_PATH_PREFIX = [...SELECT_PATH_PREFIX, 'size'];

export const SANDBOX_ALLOW_LIST = [
  'allow-same-origin',
  'allow-scripts',
  'allow-popups',
];

export const NOTIFICATION_CENTER_POPUP_LIST = ['notificationCenterPopup'];
